<template>
  <div class="card">

    <div class="card-title bg-success-800 panel-title"  style="padding-left: 15px;padding-top: 10px;" >
      <h4>
        <span class="font-weight-semibold">Quotation</span> Creation
      </h4>

    </div>


    <div class="card-body">

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Supplier</label>
            <select id="cmbsupplier" class="form-control select" autofocus="" required="" v-if="quotation"  v-model="quotation.supplier.code" >
              <option v-for="supplier in suppliers" v-bind:value="supplier.code">
                {{ supplier.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-7">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Date</label>
            <input type="date" class="form-control" v-if="quotation" v-model="quotation.date">
          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Valid Upto</label>
            <input type="date" class="form-control" v-if="quotation" v-model="quotation.valid_upto">
          </div>
        </div>

        <div class="col-md-6"></div>

        <div class="col-md-1">
          <div class="form-group form-group-material">
            <label class=" control-label font-weight-semibold">Quote No</label>
            <input type="text" class="form-control" placeholder="Quote No" v-if="quotation" v-model="quotation.quote_no">
          </div>
        </div>


        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label  font-weight-semibold">Quote Date</label>
            <input type="date" class="form-control" v-if="quotation" v-model="quotation.quote_date">
          </div>
        </div>
      </div>


      <div class="row">
        <!--  Detail Table -->
        <div class="table-responsive">

          <table id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th >Item</th>
              <th >Description</th>
              <th style="width:150px; text-align: right;">Rate / Unit</th>

              <th style="width:100px; text-align: right;">Tax</th>
              <th style="width:100px; text-align: right;">Warranty</th>
              <th style="width:100px; text-align: right;">AMC</th>
              <th style="width: 30px;">Action</th>
            </tr>

            </thead>
            <tbody v-model="quotation" v-for="(detail,index) in quotation.list" >

            <tr>
              <td style="text-align: center;"> {{index + 1}} </td>
              <td >
                <select class="form-control" v-if="detail" v-model="detail.item.code" >
                  <option v-for="item in detailItems" v-bind:value="item.code">
                    {{ item.name }}
                  </option>
                </select>
              </td>

              <td >
                <input type="text" class="form-control" placeholder="Description" maxlength="100" v-if="detail" v-model="detail.description" />
              </td>

              <td >
                <input type="number" class="form-control text-right" min="0"  step="any" placeholder="Item Rate" v-if="detail" v-model="detail.rate"  @change="rowValueEqualizer(2,index)" />
              </td>

              <td class="text-right">
                <select class="form-control" v-if="detail" v-model="detail.tax_rate" >
                  <option value="0" >0 %</option>
                  <option value="1" >1 %</option>
                  <option value="2" >2 %</option>
                  <option value="5" >5 %</option>
                  <option value="12" >12 %</option>
                  <option value="18" >18 %</option>
                  <option value="24" >24 %</option>
                </select>
              </td>

              <td >
                <input type="number" class="form-control text-right" min="0"  step="any" placeholder="Warranty Months" v-if="detail" v-model="detail.warranty_months"  @change="rowValueEqualizer(4,index)" />
              </td>

              <td >
                <input type="number" class="form-control text-right" min="0"  step="any" placeholder="AMC Percent" v-if="detail" v-model="detail.amc_percent"  @change="rowValueEqualizer(5,index)" />
              </td>


              <td class="text-center">
                <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
              </td>
            </tr>

            </tbody>
            <tfoot>
            <tr>
              <td>
                <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-right" ></td>

              <td></td>


            </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- / Detail Table -->


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="quotation" v-model="quotation.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">
          <!--<h3 style="font-size: 48px;color: blue">-->
            <!--&#8377;<span style="padding-right: 15px;">{{ indianFormat(rowAmountTotal) }}</span>-->
          <!--</h3>-->
        </div>

        <div class="col-md-3 text-right">


          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'QuotationForm',
    components: {

    },
    props: {
      myquotation: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"status":0,"type":0,"financial_year":0,"date":"0001-01-01","valid_upto":"0001-01-01","quote_no":"","quote_date":"0001-01-01","supplier":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}},"advance_amount":0,"on_initiation_amount":0,"on_complete":0,"remarks":"","list":[]}'),
      },
      FinancialYear: {
        type: Number,
        default: 2000
      }
    },
    beforeMount () {
      this.quotation = this.myquotation;
    },
    data () {
      return {
        readonly: false,
        suppliers:[],
        projects: [],
        detailItems:[],
        items : new Map(),
        rowAmountTotal: 0.0,
        quotation: JSON.parse('{"id":"","code":0,"status":0,"type":0,"financial_year":0,"date":"0001-01-01","valid_upto":"0001-01-01","quote_no":"","quote_date":"0001-01-01","supplier":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}},"advance_amount":0,"on_initiation_amount":0,"on_complete":0,"remarks":"","list":[]}'),
        seriesid: 1004
      }
    },
    created () {
      let self = this;

      self.$data.quotation.date = moment().format('YYYY-MM-DD');
      self.$data.quotation.quote_date = moment().format('YYYY-MM-DD');
      self.$data.quotation.valid_upto = moment().format('YYYY-MM-DD');

    },
    mounted () {
      let self = this;
      self.loadData();

      self.$data.quotation.date = moment().format('YYYY-MM-DD');
      self.$data.quotation.quote_date = moment().format('YYYY-MM-DD');
      self.$data.quotation.valid_upto = moment().format('YYYY-MM-DD');


      $('.form-control-select2').select2();

      $('#cmbsupplier').focus();
    },
    methods:{
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      addRow(){
        try {
          let self = this;

          self.$data.quotation.list.push(JSON.parse('{"item":{"id":"","code":0,"nature":0,"name":"","p_name":"","group_code":0,"brand_code":0,"category_code":0,"unit_code":0,"rate":0,"min_qty":0,"max_qty":0,"system_object":false},"description":"","qty":0,"rate":0,"tax_rate":0,"warranty_months":0,"amc_percent":0}'));
          // var myobj = $('table>tbody>tr:last>td:nth-child(2)').children();
          // $(myobj).focus();


        }catch (e) {
          alert(e);
        }
      },
      rowValueEqualizer(rowIndex, colIndex) {
        let self = this;

        let detail = self.$data.quotation.list[rowIndex];
        self.$data.rowAmountTotal = 0.0;
        self.$data.quotation.list.forEach(function (detail) {
          self.$data.rowAmountTotal += parseFloat(detail.value);
        });
      },

      clear(){
        let self = this;
        self.$data.quotation = JSON.parse('{"id":"","code":0,"status":0,"type":0,"financial_year":0,"date":"0001-01-01","valid_upto":"0001-01-01","quote_no":"","quote_date":"0001-01-01","supplier":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}},"advance_amount":0,"on_initiation_amount":0,"on_complete":0,"remarks":"","list":[]}');
        self.$data.quotation.date = moment().format('YYYY-MM-DD');
        self.$data.quotation.quote_date = moment().format('YYYY-MM-DD');
        self.$data.quotation.valid_upto = moment().format('YYYY-MM-DD');

        self.$data.quotation.list = [];
      },
      loadData() {
        let self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        //fetch the Ledgers
        self.$data.suppliers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/ledger/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            self.$data.suppliers = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });


        //Fetch Detail Ledgers
        self.$data.detailItems = []
        self.$data.items = new Map();
        fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/item/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
              self.$data.detailItems = resp.data;
              self.$data.detailItems.forEach(function (grp) {
              self.$data.items.set(grp.code, grp);
            });

          }else{
            swal ( {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      removeRow(index){
        if (index > -1) {
          let self = this;
          this.$data.quotation.list.splice(index, 1);
          self.$data.rowAmountTotal = 0.0;
          self.$data.quotation.list.forEach(function (detail) {
            self.$data.rowAmountTotal += parseFloat(detail.value);
          });

        }
      },
      saveVoucher(){
        let self = this;

        let myquotation =  JSON.parse(JSON.stringify(self.$data.quotation));
        myquotation.fin_year = 2018;
        myquotation.date = moment(self.$data.quotation.date).format('YYYY-MM-DD') + 'T00:00:00Z';
        myquotation.quote_date = moment(self.$data.quotation.quote_date).format('YYYY-MM-DD') + 'T00:00:00Z';
        myquotation.valid_upto = moment(self.$data.quotation.valid_upto).format('YYYY-MM-DD') + 'T00:00:00Z';


        myquotation.list.forEach(function (detail){
          detail.rate = parseFloat(detail.rate);
          detail.tax_rate = parseFloat(detail.tax_rate);
          detail.warranty_months = parseFloat(detail.warranty_months);
          detail.amc_percent = parseFloat(detail.amc_percent);
        });


        const requestOptions = {
          method:  (self.$data.quotation.code == 0 ? 'POST' : 'PUT'),
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myquotation)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/it/quotation/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            swal({
              title: 'Great',
              type: 'success',
              text: resp.message
            });
            self.clear();
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      }
    }
  }
</script>

<style scoped>
  td {
    /*text-align: center;*/
    margin: 0 !important;
    padding:0 !important;
  }
  td input,select {
    display: block !important;
    padding: 0 !important;
    margin: 0 !important;
    /*border: 0 !important;*/
    width: 100% !important;
    height: 35px !important;
    border-radius: 0 !important;
    /*line-height: 1 !important;*/
  }

  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
